import classes from './Notification.module.css';

const Notification = props => {
  const { status, title, message } = props.notification;
  let specialClasses = '';

  if (status === 'error') {
    specialClasses = classes.error;
  }
  if (status === 'success') {
    specialClasses = classes.success;
  }

  const cssClasses = `${classes.notification} ${specialClasses}`;

  return (
    <section className={cssClasses}>
      <h3>{title}</h3>
      <p>{message}</p>
    </section>
  );
};

export default Notification;
